<template lang="pug">
el-dialog.c-link-dialog(
    title="邀请海报"
    width="785px"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    v-if="course.poster_img")
    .top
        .main-img 
            el-image.img(:src="course.poster_img[cur]") 
                div(slot="placeholder" class="image-slot")
                    i(class="el-icon-picture-outline") 
        .right-box
            .info-top
                p 分销课程专属海报 
                p 学员扫码购买，你可获得相应的佣金
            .info-desc
                h3 推荐使用场景
                p 1.朋友圈、微博、贴吧等社交平台 
                p(style="margin-bottom:20px") 2.社交聊天软件对话。
                h3 推荐使用场景
                p 使用场景丰富，可实现多次转化
            ul
                li(
                    v-for="(item,index) in course.poster_img" 
                    :key="index" 
                    @click="handleCur(index)")
                    //- img(:src="item")
                    el-image.img(:src="item" @load="load(index,$event)")
                        div(slot="placeholder" class="image-slot")
                            i(class="el-icon-picture-outline") 
                    .mark(v-if="cur === index && markShow")
                        img(src="../assets/images/radio.png")
    el-button(type="primary" @click="downLoad") 下载海报
</template>
<script>
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        imgCourse: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            course: {},
            cur: 0,
            markShow: false
        };
    },
    methods: {
        change() {
            this.course = this.imgCourse;
        },
        handleCur(index) {
            if (this.markShow) this.cur = index;
        },
        handleClose() {
            this.$emit("close");
        },
        load(index, e) {
            if (index === 0) this.markShow = true;
        },
        downLoad() {
            let url = this.course.poster_img[this.cur];
            this.downloadIamge(url, "pic");
        },
        //利用canvas转base64下载
        downloadIamge(imgsrc, name) {
            var image = new Image();
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                var url = canvas.toDataURL("image/png");
                var a = document.createElement("a");
                var event = new MouseEvent("click");
                a.download = name || "photo";
                a.href = url;
                a.dispatchEvent(event);
            };
            image.src = `${imgsrc}?v=${Date.now()}`;
        }
    },
    watch: {
        imgCourse: "change"
    }
};
</script>
<style lang="scss">
.c-link-dialog {
    .el-dialog__header {
        height: 60px;
        line-height: 60px;
        background: linear-gradient(135deg, #517bfc, #517bfc);
        padding: 0 30px;
        .el-dialog__headerbtn {
            border-color: transparent #7c9cfd transparent transparent;
        }
        .el-dialog__title {
            font-size: 18px;
            font-weight: 500 !important;
            display: block !important;
            height: 100%;
            line-height: 60px;
            text-align: center;
        }
    }
    .el-dialog__body {
        padding: 40px;
        background: #fff;
        .top {
            display: flex;
            align-items: top;
            margin-bottom: 30px;
            .main-img {
                width: 255px;
                height: 440px;
                margin-right: 50px;
                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    .image-slot {
                        width: 100%;
                        height: 100%;
                        background: #f8f8f8;
                        animation: fade 2s infinite;
                        position: relative;
                        .el-icon-picture-outline {
                            color: #ccc;
                            font-size: 50px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
            .right-box {
                // height: 440px;
                padding-top: 20px;
                box-sizing: border-box;
                width: calc(100% - 305px);
                .info-top {
                    margin-bottom: 24px;
                    p {
                        font-size: 15px;
                        color: #3a3a3a;
                    }
                }
                .info-desc {
                    padding: 23px 20px 23px 20px;
                    box-sizing: border-box;
                    background: #f7f8f9;
                    margin-bottom: 30px;
                    h3 {
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-bottom: 10px;
                        line-height: 18px;
                    }
                    p {
                        font-size: 14px;
                        color: #4c4c4c;
                    }
                }
                ul {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    flex-wrap: wrap;
                    li {
                        flex-shrink: 0;
                        width: 70px;
                        height: 124px;
                        position: relative;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        .img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            .image-slot {
                                width: 100%;
                                height: 100%;
                                background: #f8f8f8;
                                animation: fade 2s infinite;
                                position: relative;
                                .el-icon-picture-outline {
                                    color: #ccc;
                                    font-size: 30px;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                        .mark {
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.6);
                            position: absolute;
                            top: 0;
                            left: 0;
                            img {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                right: 5px;
                                bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
        button {
            width: 180px;
            height: 40px;
            display: block;
            margin: auto;
        }
    }

    @keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
}
</style>
