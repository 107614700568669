<template lang="pug">
el-dialog.v-link-dialog(:visible.sync="dialogVisible" :before-close="handleClose" title="精彩试看" width="750px")
    .video-course 
        video(controls :poster="poster" :src="videoSrc" autoplay ref="video")
</template>
<script>
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        videoSrc: {
            type: String,
            default: () => {
                return "";
            }
        },
        poster: {
            type: String,
            default: () => {
                return "";
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit("close");
        }
    },
    watch: {
        dialogVisible(v) {
            if (!v) {
                this.$refs.video.pause();
            } else {
                setTimeout(() => {
                    this.$refs.video.play();
                }, 100);
            }
        }
    }
};
</script>
<style lang="scss">
.v-link-dialog {
    .el-dialog__header {
        height: 60px;
        line-height: 60px;
        background: linear-gradient(135deg, #517bfc, #517bfc);
        padding: 0 30px;
        .el-dialog__headerbtn {
            border-color: transparent #7c9cfd transparent transparent;
        }
        .el-dialog__title {
            font-size: 18px;
            font-weight: 500 !important;
            display: block !important;
            height: 100%;
            line-height: 60px;
            text-align: center;
        }
    }
    .el-dialog__body {
        padding: 0 !important;
        background: #fff;
        .video-course {
            height: 410px;
            width: 750px;
            video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
</style>
