<template lang="pug">
  el-dialog.d-link-dialog(
    :visible='dialog'
    width='600px'
    title="分销链接"
    :before-close="handleClose")
    .tips 通过此链接完成购买即可获得佣金
    .d-link-input
      label 专属链接:
      el-input(id="linkInput" v-model='url' readonly)
      el-button.primary-btn(@click="copyLink") 复制
    .d-link-img
      label 二维码:
      img.qrcode-img(:src="img")
      .tips 点击右键选择“另存为”或截图
</template>

<script>
export default {
    props: ["dialog", "url", "img"],
    data() {
        return {};
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        copyLink() {
            var input = document.getElementById("linkInput");
            input.select();
            document.execCommand("copy");
            this.successMes("复制成功", 1000);
        }
    }
};
</script>

<style lang="less">
.d-link-dialog {
    .el-dialog__header {
        height: 60px;
        line-height: 60px;
        background: linear-gradient(135deg, #517bfc, #517bfc);
        padding: 0 30px;
        .el-dialog__headerbtn {
            border-color: transparent #7c9cfd transparent transparent;
        }
        .el-dialog__title {
            font-size: 18px;
            font-weight: 500 !important;
        }
    }
    .el-dialog__body {
        background: #fff;
        padding: 25px 30px 60px;
        & > .tips {
            color: #666;
            margin-bottom: 20px;
        }
    }

    .d-link-input {
        display: flex;
        margin-bottom: 20px;
        label {
            width: 100px;
            line-height: 40px;
        }
        .el-input {
            width: 380px;
            margin-right: 10px;
        }
        .el-button {
            width: 98px;
        }
    }
    .d-link-img {
        text-align: center;
        label {
            display: block;
            text-align: left;
            width: 100%;
        }
        .qrcode-img {
            display: block;
            width: 200px;
            height: 200px;
            margin: 25px auto 15px;
        }
        .tips {
            color: #666;
        }
    }
}
</style>
