<template>
    <div class="course-detail-page">
        <div class="pos-fixed">
            <top-bar></top-bar>
            <first-head></first-head>
        </div>
        <div class="pt-top-head-height">
            <div class="content">
                <div class="top-baseInfo">
                    <div class="course-img">
                        <img :src="courseData.top_banner_img" />
                        <div class="video-type" v-if="courseData.try_it">
                            精彩试看
                        </div>
                        <div
                            class="play-icon"
                            v-if="courseData.try_it"
                            @click="handleVideo(courseData.try_it)"
                        >
                            <div class="arrow"></div>
                        </div>
                    </div>
                    <div class="course-info">
                        <div class="title">{{ courseData.goods_name }}</div>
                        <div class="describe">{{ courseData.describe }}</div>
                        <div
                            class="else-info"
                            v-if="el_type == 33 || el_type == 34 ? false : true"
                        >
                            <span class="key">商品售价:</span
                            ><span class="val"
                                >¥{{ courseData.goods_price }}</span
                            ><span class="key">赚取:</span
                            ><span class="val red"
                                >¥{{ courseData.commission_price }}</span
                            ><span class="key">{{
                                el_type == 14 ||
                                el_type == 31 ||
                                el_type == 32 ||
                                el_type == 33 ||
                                el_type == 34
                                    ? "领取人数"
                                    : "学习人数"
                            }}</span
                            ><span class="val">{{
                                courseData.order_count
                            }}</span>
                        </div>
                        <div class="free-course" v-else>
                            <span>免费课程</span>
                            <span>助你快速增粉</span>
                        </div>
                        <el-button
                            class="primary-btn"
                            @click="toDistribution"
                            >{{
                                el_type == 33 || el_type == 34
                                    ? "免费领取"
                                    : "立即赚取"
                            }}</el-button
                        >
                        <el-button
                            class="share-btn"
                            plain
                            @click="getShareCourse(courseData.id)"
                            >分享海报</el-button
                        >
                    </div>
                </div>
                <div class="swiper-product" v-if="courseData.product_list">
                    <swiper
                        class="product-course-lists"
                        v-if="courseData.product_list.length > 0"
                        :options="swiperCourseOption"
                        ref="mySwiper"
                    >
                        <swiper-slide
                            class="swiper-slide"
                            v-for="(item, index) in courseData.product_list"
                            :key="index"
                        >
                            <div class="book-item-hc c-pointer">
                                <img
                                    :src="item.top_banner_img"
                                    :keyId="item.id"
                                />
                                <div class="name">{{ item.goods_name }}</div>
                                <div class="describe">{{ item.describe }}</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-button swiper-button-prev"></div>
                    <div class="swiper-button swiper-button-next"></div>
                </div>
                <div class="bottom-detail">
                    <nav class="title">
                        <div
                            :class="{ active: index === cur }"
                            v-for="(item, index) in nav"
                            :key="index"
                            @click="handleNav(item, index)"
                        >
                            {{ item }}
                        </div>
                    </nav>
                    <div class="detail-content">
                        <div
                            class="iframe-box"
                            style="height:auto;"
                            v-show="isShow"
                        >
                            <iframe
                                ref="iframe"
                                id="external-frame"
                                name="iframe"
                                width="100%"
                                height="1000px"
                                frameborder="0"
                                scrolling="auto"
                            ></iframe>
                        </div>
                        <div
                            v-show="!isShow"
                            class="content-synopsis"
                            v-html="courseData.details_intro"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <bottomFoot></bottomFoot>
        <d-link
            :dialog="dialog"
            :url="dLinkUrl"
            :img="dLinkImg"
            @close="closeCaDialog"
        ></d-link>
        <share-popup
            :dialogVisible="dialogVisible"
            :imgCourse="imgCourse"
            @close="closeShareDialog"
        ></share-popup>
        <video-popup
            :dialogVisible="dialogVisibleVideo"
            :videoSrc="courseData.try_it"
            :poster="courseData.top_banner_img"
            @close="dialogVisibleVideo = false"
        ></video-popup>
    </div>
</template>
<script>
import { axios } from "@/utils/request.js";
import {
    getProductDetail,
    getProductsDetail,
    getDistributionUrl,
    getDistributionPosters
} from "@/api/product.js";
import dLink from "@/components-biz/d-link.vue";
import sharePopup from "@/components-biz/share-popup.vue";
import videoPopup from "@/components-biz/video-popup.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
    components: { dLink, swiper, swiperSlide, sharePopup, videoPopup },
    data() {
        return {
            nav: [],
            cur: 0,
            isShow: true,
            type: null,
            courseId: null,
            courseData: {},
            dialog: false,
            dLinkUrl: "",
            dLinkImg: "",
            swiperCourseOption: {
                slidesPerView: 7,
                spaceBetween: 0,
                preventClicksPropagation: false,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },
            el_type: null,
            dialogVisible: false,
            dialogVisibleVideo: false,
            imgCourse: {}
        };
    },
    created() {
        //0->单品，1->组合
        this.type = this.$route.query.type;
    },
    mounted() {
        this.courseId = this.$route.query.id;
        this.getData(this.type);
        let _this = this;
        this.swiperCourseOption.on = {
            click(e) {
                var id = e.target.getAttribute("keyId");
                if (!id) return;
                _this.goDetail(id);
            }
        };
    },
    computed: {
        user_id() {
            let uId = 0;
            if (this.$store.state.userInfo.userInfo) {
                uId = this.$store.state.userInfo.userInfo.user_id;
            }
            return uId;
        }
    },
    methods: {
        getData(type = 0) {
            if (type == "0") {
                getProductDetail(this.courseId, {
                    params: {
                        user_id: this.user_id
                    }
                }).then(res => {
                    if (res.code == 0) {
                        this.el_type = res.data.type;
                        this.courseData = res.data;
                        this.nav = res.data.tab;
                        // console.log("0", res.data);
                        if (res.data.promotion_link) {
                            this.getUrl(res.data.promotion_link);
                        } else {
                            this.isShow = false;
                        }

                        if (res.data.is_distributed) {
                            this.dLinkUrl = res.data.distribution_url;
                            this.dLinkImg = res.data.distribution_qr_code;
                        }
                    }
                });
            } else {
                getProductsDetail(this.courseId, {
                    params: {
                        user_id: this.user_id
                    }
                }).then(res => {
                    if (res.code == 0) {
                        this.el_type = res.data.type;
                        this.courseData = res.data;
                        this.nav = res.data.tab;
                        // console.log("1", res.data);
                        if (res.data.promotion_link) {
                            this.getUrl(res.data.promotion_link);
                        } else {
                            this.isShow = false;
                        }
                        this.swiperCourseOption.loop =
                            res.data.product_list.length > 7;
                        if (res.data.is_distributed) {
                            this.dLinkUrl = res.data.distribution_url;
                            this.dLinkImg = res.data.distribution_qr_code;
                        }
                    }
                });
            }
        },
        toDistribution() {
            if (this.user_id) {
                if (!this.courseData.is_distributed) {
                    // 获取分销链接接口
                    this.getDistributionUrl();
                } else {
                    this.dialog = true;
                }
            } else {
                this.$store.commit("CONTROL_LOGIN_DIALOG", true);
            }
        },
        getDistributionUrl() {
            getDistributionUrl(this.courseId, {
                params: {
                    user_id: this.user_id
                }
            }).then(res => {
                if (res.code == 0) {
                    this.dLinkUrl = res.data.distribution_url;
                    this.dLinkImg = res.data.distribution_qr_code;
                    this.dialog = true;
                }
            });
        },
        getShareCourse(id) {
            if (this.user_id) {
                getDistributionPosters(id).then(res => {
                    this.imgCourse = res.data;
                    this.dialogVisible = true;
                });
            } else {
                this.$store.commit("CONTROL_LOGIN_DIALOG", true);
            }
        },

        closeCaDialog() {
            this.dialog = false;
        },
        closeShareDialog() {
            this.dialogVisible = false;
        },
        handleNav(text, index) {
            if (text == "商品详情") this.isShow = false;
            else if (text == "推广链接") this.isShow = true;
            this.cur = index;
        },
        getUrl(url) {
            let http =
                window.location.protocol === "http:" ? "http:" : "https:";
            //调用跨域API
            let realurl = `${http}//cors-anywhere.herokuapp.com/${url}`;
            axios.get(realurl).then(
                response => {
                    let html = response.data;
                    html = html
                        .replace(
                            /data-src="/g,
                            `src="${process.env.VUE_APP_BASE_API}/wx_img?url=`
                        )
                        .replace(
                            /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g,
                            ""
                        )
                        .replace(/https/g, "http");
                    let html_src = html;
                    let iframe = this.$refs.iframe;
                    iframe.src = html_src;
                    var doc = iframe.contentDocument || iframe.document;
                    doc.write(html_src);
                    doc.getElementById("js_content").style.visibility =
                        "visible";
                },
                err => {
                    this.nav = ["商品详情"];
                    this.isShow = false;
                }
            );
        },
        //轮播去详情页
        goDetail(id) {
            let routeData = this.$router.resolve({
                name: "courseDetail",
                query: {
                    id: id,
                    type: 0
                }
            });
            window.open(routeData.href, "_blank");
        },
        //精彩试看
        handleVideo(src) {
            this.dialogVisibleVideo = true;
        }
    }
};
</script>

<style lang="less">
.course-detail-page {
    .content {
        width: 1200px;
        margin: 0 auto;
        padding: 50px 0;
        .top-baseInfo {
            display: flex;
            margin-bottom: 50px;
            .course-img {
                width: 340px;
                height: 186px;
                margin-right: 30px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
                .video-type {
                    width: 100%;
                    height: 32px;
                    background: rgba(0, 0, 0, 0.65);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    color: #fff;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 0 0 5px 5px;
                }
                .play-icon {
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.65);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    .arrow {
                        width: 0;
                        height: 0;
                        display: block;
                        border-bottom: 6px solid transparent;
                        border-left: 10px solid #222;
                        border-top: 6px solid transparent;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 10;
                    }
                }
            }
            .course-info {
                width: 830px;
                font-size: 14px;
                color: #666;
                line-height: 20px;
                .title {
                    color: #333;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 10px;
                }
                .describe {
                    height: 52px;
                    line-height: 26px;
                    overflow: hidden;
                    margin-bottom: 20px;
                }
                .else-info {
                    margin-bottom: 30px;
                    .val {
                        color: #333;
                        font-weight: 500;
                        margin-right: 50px;
                        margin-left: 8px;
                    }
                    .red {
                        color: #fb3e31;
                    }
                }
                .free-course {
                    font-size: 14px;
                    font-weight: 500;
                    color: #fc5107;
                    line-height: 20px;
                    margin-bottom: 30px;
                    span {
                        &:first-child {
                            margin-right: 30px;
                        }
                    }
                }
                .primary-btn {
                    width: 120px;
                    height: 32px;
                    line-height: 32px;
                    vertical-align: middle;
                }
                .share-btn {
                    width: 120px;
                    height: 34px;
                    line-height: 8px;
                    vertical-align: middle;
                    border-color: #517bfc;
                    color: #517bfc;
                }
            }
        }
        .swiper-product {
            width: 100%;
            position: relative;
            margin-bottom: 50px;
            .product-course-lists {
                width: 1140px;
                height: 165px;
                padding-top: 5px;
                margin: 0 30px;
                .book-item-hc {
                    box-sizing: border-box;
                    width: 160px;
                    height: 163px;
                    padding: 10px;
                    font-size: 14px;
                    color: #333;
                    line-height: 1;
                    text-align: left;
                    img {
                        box-sizing: border-box;
                        width: 100%;
                        height: 87px;
                        margin-bottom: 8px;
                        border-radius: 5px;
                    }
                    .name,
                    .describe {
                        box-sizing: border-box;
                        width: 100%;
                        height: 22px;
                        line-height: 22px;
                        font-size: 16px;
                        margin-bottom: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .describe {
                        font-size: 13px;
                        color: #888;
                    }
                }
                .book-item-hc:hover {
                    box-shadow: 0px 2px 4px 0px rgba(42, 103, 255, 0.3);
                }
            }
            .swiper-button {
                position: absolute;
                width: 30px;
                height: 100px;
                background-color: #ddd;
                background-size: 10px 20px;
                border-radius: 4px;
                top: 33px;
                margin: 0;
                opacity: 0.5;
                z-index: 3;
            }
            .swiper-button-disabled {
                opacity: 0;
            }
            .swiper-button:hover {
                background-color: #e2eaff;
            }
            .swiper-button:active {
                background-color: #e2eaff;
            }
            .swiper-button-prev {
                left: 0;
                background-image: url("~@/assets/images/left.png");
            }
            .swiper-button-next {
                right: 0;
                background-image: url("~@/assets/images/right.png");
            }
        }
        .bottom-detail {
            .title {
                font-size: 20px;
                color: #333;
                font-weight: 500;
                line-height: 28px;
                padding-bottom: 10px;
                border-bottom: 1px solid #ced1e9;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                div {
                    margin-right: 40px;
                    cursor: pointer;
                }
                .active {
                    color: #2a67ff;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -11px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 40px;
                        height: 2px;
                        border-radius: 1px;
                        background: #2a67ff;
                    }
                }
            }

            .detail-content {
                .sub-title {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    padding-left: 13px;
                    font-size: 16px;
                    color: #333;
                    line-height: 22px;
                    font-weight: 500;
                    margin-bottom: 17px;
                }
                .sub-title:before {
                    content: "";
                    width: 3px;
                    height: 17px;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    background: #2a67ff;
                }
                .content-synopsis {
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                }
                .iframe-box {
                    width: 720px;
                    min-height: 600px;
                    // overflow-y: scroll;
                    iframe {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
